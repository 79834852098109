import { Button, DatePicker, Input } from "antd";
import Flex from "components/shared-components/Flex";
import { isEmpty } from "lodash";
import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";

export const initSearch = {
  startDate: null,
  endDate: null,
  searchText: null,
};

export default function FilterBar({
  CustomComponent,
  loading,
  filter,
  setFilter,
  showButton = false,
  showDate = true,
  ButtonName = "Thêm",
  onHandle,
}) {
  const onChangeSearch = (text) => {
    setFilter({ ...filter, searchText: text });
  };

  const handleChangeDatePicker = (value) => {
    let startDate = "";
    let endDate = "";
    if (!isEmpty(value)) {
      startDate = value[0].toISOString();
      endDate = value[1].toISOString();
    } else {
      startDate = null;
      endDate = null;
    }
    setFilter({ ...filter, endDate, startDate });
  };

  return (
    <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
      <Flex className="mb-1" mobileFlex={false}>
        <div className="mr-md-3 mb-3">
          <Input.Search
            placeholder="Tìm kiếm"
            onSearch={onChangeSearch}
            loading={loading}
          />
        </div>
        {CustomComponent}
        {showDate && (
          <div className="mr-md-3 mb-3">
            <DatePicker.RangePicker onChange={handleChangeDatePicker} />
          </div>
        )}
      </Flex>
      <Flex>
        {showButton && (
          <Button
            onClick={onHandle}
            type="primary"
            className="mb-3"
            icon={<PlusCircleOutlined />}
            block
          >
            {ButtonName}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
