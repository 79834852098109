import axiosInstance from "auth/FetchInterceptor";
import { BANNER_API, SEARCH_BANNER_API, SETTING } from "constants/ApiConstant";
const SettingService = {};

SettingService.searchGridBanner = function (data) {
  return axiosInstance.post(SEARCH_BANNER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

SettingService.bannerById = function (id) {
  return axiosInstance.get(`${BANNER_API}?Id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

SettingService.createBanner = function (data) {
  return axiosInstance.post(BANNER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

SettingService.updateBanner = function (data) {
  return axiosInstance.put(BANNER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

SettingService.deleteBanner = function (id) {
  return axiosInstance.delete(`${BANNER_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

SettingService.getAllSetting = function () {
  return axiosInstance.get(`${SETTING}/get-all`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

SettingService.updateSetting = function (data) {
  return axiosInstance.put(`${SETTING}/update`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default SettingService;
