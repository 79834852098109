import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  CDHA_PREFIX_PATH,
} from "configs/AppConfig";

export const publicShareRoute = [
  {
    key: "rating.submit",
    path: `/customer/rating-form`,
    component: React.lazy(() =>
      import("views/app-views/crm/ratings/FormRatingSubmit")
    ),
  },
  {
    key: "esign",
    path: `/customer/esign`,
    component: React.lazy(() => import("views/app-views/crm/esign/index")),
  },
];
export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
  {
    key: "crm-cdha",
    path: `${CDHA_PREFIX_PATH}/cdha`,
    component: React.lazy(() => import("views/cdha-views")),
  },  
  {
    key: "crm-medic-portal",
    path: `/portal/medic-info`,
    component: React.lazy(() => import("views/portal-view/mymedic")),
  },
  {
    key: "crm-medic-portal2",
    path: `/medic-info`,
    component: React.lazy(() => import("views/portal-view/mymedic")),
  },
  {
    key: "crm-yte-medic-portal",
    path: `/portal/y-te-medic-info`,
    component: React.lazy(() => import("views/portal-view/mymedic")),
  },
  {
    key: "crm-yte-medic-portal2",
    path: `/y-te-medic-info`,
    component: React.lazy(() => import("views/portal-view/mymedic")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboard")),
  },
  {
    key: "crm.report.chatluongdichvu",
    path: `${APP_PREFIX_PATH}/crm/bao-cao/chat-luong-dich-vu`,
    component: React.lazy(() => import("views/app-views/crm/dashboards")),
  },
  {
    key: "crm.report.hoatdongkcb",
    path: `${APP_PREFIX_PATH}/crm/bao-cao/hoat-dong-kcb`,
    component: React.lazy(() =>
      import("views/app-views/crm/dashboards/kcbreport")
    ),
  },
  {
    key: "hrm-employee",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien`,
    component: React.lazy(() => import("views/app-views/hrm/employee")),
  },
  {
    key: "hrm-employee",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/import`,
    component: React.lazy(() => import("views/app-views/hrm/employee/import")),
  },
  {
    key: "cate-department",
    path: `${APP_PREFIX_PATH}/hrm/thiet-lap/co-cau-to-chuc`,
    component: React.lazy(() => import("views/app-views/hrm/department")),
  },
  {
    key: "branch-auth",
    path: `${APP_PREFIX_PATH}/hrm/phan-quyen/phan-quyen-chi-nhanh`,
    component: React.lazy(() =>
      import("views/app-views/permitRole/userBranch")
    ),
  },
  {
    key: "hrm.bhytcategory",
    path: `${APP_PREFIX_PATH}/hrm/thiet-lap/bhyt-dsnv`,
    component: React.lazy(() =>
      import("views/app-views/hrm/setting/bhxh-dmnv")
    ),
  },
  {
    key: "scope-auth",
    path: `${APP_PREFIX_PATH}/hrm/phan-quyen/phan-quyen-phan-he`,
    component: React.lazy(() => import("views/app-views/permitRole/scopeAuth")),
  },
  {
    key: "role-manager",
    path: `${APP_PREFIX_PATH}/hrm/phan-quyen/quan-ly-vai-tro`,
    component: React.lazy(() =>
      import("views/app-views/permitRole/roleManager")
    ),
  },
  {
    key: "permit-employee",
    path: `${APP_PREFIX_PATH}/hrm/phan-quyen/phan-quyen-nhan-vien`,
    component: React.lazy(() => import("views/app-views/permitRole/userRole")),
  },
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}/setting-account/*`,
    component: React.lazy(() => import("views/app-views/setting-accont")),
  },
  {
    key: "hospital-management",
    path: `${APP_PREFIX_PATH}/crm/benh-vien`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Hospital")
    ),
  },
  {
    key: "doctor-management",
    path: `${APP_PREFIX_PATH}/crm/bac-sy`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Doctor")
    ),
  },
  {
    key: "appointment-management",
    path: `${APP_PREFIX_PATH}/crm/dang-ky-kcb`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Appointment")
    ),
  },
  {
    key: "posts-management",
    path: `${APP_PREFIX_PATH}/crm/tin-tuc`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Posts")
    ),
  },
  {
    key: "edit-post",
    path: `${APP_PREFIX_PATH}/crm/tin-tuc/*`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Posts/CreateAndUpdatePosts")
    ),
  },
  {
    key: "setting-banner",
    path: `${APP_PREFIX_PATH}/crm/thiet-lap`,
    component: React.lazy(() => import("views/app-views/crm/setting")),
  },
  {
    key: "notification-management",
    path: `${APP_PREFIX_PATH}/crm/thong-bao`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Notification")
    ),
  },
  {
    key: "edit-notification-management",
    path: `${APP_PREFIX_PATH}/crm/thong-bao/*`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Notification/CreateNotification")
    ),
  },
  {
    key: "ambulance-service-management",
    path: `${APP_PREFIX_PATH}/crm/dich-vu-goi-xe`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Ambulance")
    ),
  },
  {
    key: "ambulance-service-management",
    path: `${APP_PREFIX_PATH}/crm/dich-vu-goi-xe`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Ambulance")
    ),
  },
  {
    key: "comment-management",
    path: `${APP_PREFIX_PATH}/crm/quan-ly-hoi-dap`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Comment")
    ),
  },
  {
    key: "hr-c1",
    path: `${APP_PREFIX_PATH}/hrm/ho-so-cap-1`,
    component: React.lazy(() =>
      import("views/app-views/hrm/hr-records/c1")
    ),
  },
  {
    key: "hr-c2",
    path: `${APP_PREFIX_PATH}/hrm/ho-so-cap-2`,
    component: React.lazy(() =>
      import("views/app-views/hrm/hr-records/c2")
    ),
  },
];
