import { Button, Card, Input, Popconfirm, Spin, Table } from "antd";
import FilterBar from "components/searchBar/FilterBar";
import React, { useEffect, useMemo } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  StopOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addNewHSC1, searchHSC1, setHoSo1, updateHSC1 } from "store/slices/hrRecordSlice";
import Utils from "utils";
import { isEmpty } from "lodash";

export const initHS1 = {
  action: "initial",
};

const HoSoCap1 = () => {
  const dispatch = useDispatch();
  const { loading, searchHs1 } = useSelector((state) => state.hrRecord);

  useEffect(() => {
    dispatch(searchHSC1());
  }, [dispatch]);

  const onClickAction = (record, index) => {
    const newData = searchHs1.slice(0);
    if (record.action === initHS1.action) {
      const payload = {
        name: record.name,
        note: record.note,
        onSuccess: () => {
          dispatch(searchHSC1());
        },
      };
      dispatch(addNewHSC1(payload));
    } else {
      if (!isEmpty(record.id) && record.action !== "update") {
        newData[index] = { ...newData[index], action: "update" };
      } else {
        const payload = {
          id: record.id,
          name: record.name,
          note: record.note,
          onSuccess: () => {
            dispatch(searchHSC1());
          },
        };
        dispatch(updateHSC1(payload))
      }
      dispatch(setHoSo1(newData));
    }
  };

  const onChangeText = (type, index) => (e) => {
    const newData = searchHs1.slice(0);
    const value = e?.target?.value ?? e;
    newData[index] = { ...newData[index], [type]: value };
    dispatch(setHoSo1(newData));
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Tên tài liệu",
        dataIndex: "name",
        render: (_, record, index) =>
          record.action ? (
            <Input
              value={record.name}
              onChange={onChangeText("name", index)}
              placeholder="Nhập tên tài liệu..."
            />
          ) : (
            record.name
          ),
      },
      {
        title: "Người tạo",
        align: "center",
        dataIndex: "createdById",
      },
      {
        title: "Thời gian tạo",
        align: "center",
        dataIndex: "createdAt",
        render: (date) => date && Utils.formatDate(date),
      },
      {
        title: "Ghi chú",
        dataIndex: "note",
        render: (_, record, index) =>
          record.action ? (
            <Input
              value={record.note}
              onChange={onChangeText("note", index)}
              placeholder="Nhập ghi chú..."
            />
          ) : (
            record.note
          ),
      },
      {
        fixed: "right",
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                disabled={!record.name}
                title={"Thêm thông tin"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  title={"Sửa thông tin"}
                  onClick={() => onClickAction(record, index)}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {}}
                >
                  <Button
                    title={"Xóa thông tin"}
                    className="mr-2"
                    icon={<StopOutlined />}
                    shape="circle"
                  />
                </Popconfirm>
                <Popconfirm
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {}}
                >
                  <Button
                    title={"Xóa thông tin"}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchHs1]
  );

  return (
    <Card>
      <h3>HỒ SƠ CẤP 1</h3>
      <Spin tip="Đang tải..." spinning={false}>
        <FilterBar showButton />
        <Table
          columns={tableColumns}
          dataSource={searchHs1}
          scroll={{ x: "max-content" }}
          rowKey={(item) => item?.id}
        />
      </Spin>
    </Card>
  );
};

export default HoSoCap1;
