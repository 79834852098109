import axiosInstance from "auth/FetchInterceptor";
import {
  GET_ALL_HOSPITAL_API,
  GET_BY_DEPARTMENT_API,
  GET_BY_DEPARTMENT_LEVEL_API,
  GET_BY_HIS_DEPARTMENT_API,
  GET_BY_JOB_POSITION_API,
  GET_BY_JOB_SPECCIALIZE_API,
  GET_BY_JOB_TITLE_API,
  GET_BY_WORK_STATUS_API,
  GET_DEAPARTMENT_BY_BRANCH,
  GET_SPECALIZE_BY_BRANCH,
  GET_TIME_SLOT,
} from "constants/ApiConstant";
const CategoryService = {};

CategoryService.getByJobPosition = function (data) {
  return axiosInstance.get(GET_BY_JOB_POSITION_API, null);
};
CategoryService.getByJobSpecialize = function (data) {
  return axiosInstance.get(GET_BY_JOB_SPECCIALIZE_API, null);
};
CategoryService.getByJobTitle = function (data) {
  return axiosInstance.get(GET_BY_JOB_TITLE_API, null);
};
CategoryService.getByDepartment = function (branchId) {
  return axiosInstance.get(`${GET_BY_DEPARTMENT_API}/${branchId}`, null);
};
CategoryService.getByWorkStatus = function (data) {
  return axiosInstance.get(GET_BY_WORK_STATUS_API, null);
};
CategoryService.getByHisDepartment = function (branchId) {
  return axiosInstance.get(GET_BY_HIS_DEPARTMENT_API, null);
};
CategoryService.getByDepartmentLevel = function (data) {
  return axiosInstance.get(GET_BY_DEPARTMENT_LEVEL_API, null);
};
CategoryService.getDepartmentByBranch = function (id) {
  return axiosInstance.get(`${GET_DEAPARTMENT_BY_BRANCH}/${id}`, null);
};
CategoryService.getSpecialityAPI = function () {
  return axiosInstance.get(GET_SPECALIZE_BY_BRANCH, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
CategoryService.getTimeSlotAPI = function () {
  return axiosInstance.get(GET_TIME_SLOT, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CategoryService.getHospitalApi = function () {
  return axiosInstance.get(GET_ALL_HOSPITAL_API, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default CategoryService;
