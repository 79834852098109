import axiosInstance from "auth/FetchInterceptor";
import {
  REPORT_CHART_ALL_HOSPITAL_API,
  REPORT_CHART_HOSPITAL_API,
  REPORT_CHART_KCB_ALL_HOSPITAL_API,
  REPORT_CHART_KCB_HOSPITAL_API,
  REPORT_FEEDBACK_FROM_CUSTOMER_API,
} from "constants/ApiConstant";
const ReportService = {};

ReportService.getFeedBackFromCustomer = function (data) {
  return axiosInstance.post(REPORT_FEEDBACK_FROM_CUSTOMER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportThisHospital = function (data) {
  return axiosInstance.post(REPORT_CHART_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportAllHospital = function (data) {
  return axiosInstance.post(REPORT_CHART_ALL_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportKcbAllHospital = function (data) {
  return axiosInstance.post(REPORT_CHART_KCB_ALL_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportKcbHospital = function (data) {
  return axiosInstance.post(REPORT_CHART_KCB_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default ReportService;
