import axiosInstance from "auth/FetchInterceptor";
import {
  BHYT_APPLY_DSNV,
  BHYT_SEARCH_DSNV
} from "constants/ApiConstant";
const BhxhCategoryService = {};

BhxhCategoryService.searchGrid = function (data) {
  return axiosInstance.post(BHYT_SEARCH_DSNV, data);
};

BhxhCategoryService.applyImportDsnv = function (data) {
  return axiosInstance.post(BHYT_APPLY_DSNV, data);
};

export default BhxhCategoryService;
