import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";

export const PUBLICROUTES = [
  "/crm/cdha",
  "/medic-info",
  "/y-te-medic-info"
];
const PublicRoute = () => {
  
  const {pathname} = useLocation();
  const { token } = useSelector((state) => state.auth);
  const isPublicRoute = PUBLICROUTES.find(item => pathname.includes(item));
  return token && !isPublicRoute   ? (
    <Navigate to={AUTHENTICATED_ENTRY} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
