import axiosInstance from "auth/FetchInterceptor";
import {
  USER_BRANCH_API,
  USER_ROLE_API,
  USER_SCOPE_API,
} from "constants/ApiConstant";
const UserService = {};

UserService.getUserBranchPermission = function (text = "") {
  return axiosInstance.get(`${USER_BRANCH_API}?freeText=${text}`, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};
UserService.updateUserBranchPermission = function (data) {
  return axiosInstance.put(USER_BRANCH_API, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};
UserService.getUserScopePermissionById = function (data) {
  return axiosInstance.get(`${USER_SCOPE_API}/${data.id}?freeText=${data?.freeText || ""}`, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};
UserService.updateUserScopePermission = function (data) {
  return axiosInstance.put(USER_SCOPE_API, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

UserService.getUserRolePermission = function (data) {
  return axiosInstance.get(
    `${USER_ROLE_API}/${data.id}?freeText=${data?.freeText || ""}`,
    {
      baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
    }
  );
};
UserService.updateUserRolePermission = function (data) {
  return axiosInstance.put(USER_ROLE_API, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

export default UserService;
