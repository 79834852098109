import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import HRRecordService from "services/HRRecordService";
import { initHS1 } from "views/app-views/hrm/hr-records/c1";

export const searchHSC1 = createAsyncThunk(
  "hrRecord/searchHSC1",
  async (data, { rejectWithValue }) => {
    try {
      const response = await HRRecordService.searchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addNewHSC1 = createAsyncThunk(
  "hrRecord/addNewHSC1",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HRRecordService.addHSC1(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateHSC1 = createAsyncThunk(
  "hrRecord/updateHSC1",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HRRecordService.updateHSC1(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteHSC1 = createAsyncThunk(
  "hrRecord/deleteHSC1",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await HRRecordService.deleteHSC1(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  searchHs1: [],
};

export const hrRecordSlice = createSlice({
  name: "hrRecord",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setHoSo1: (state, action) => {
      state.searchHs1 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchHSC1.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchHSC1.fulfilled, (state, action) => {
        state.loading = false;
        state.searchHs1 = [initHS1, ...action.payload];
      })
      .addCase(searchHSC1.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewHSC1.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewHSC1.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewHSC1.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateHSC1.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHSC1.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateHSC1.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteHSC1.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteHSC1.fulfilled, (state, action) => {
        state.loading = false;
        state.searchHs1 = action.payload;
      })
      .addCase(deleteHSC1.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setHoSo1 } = hrRecordSlice.actions;

export default hrRecordSlice.reducer;
