import axiosInstance from "auth/FetchInterceptor";
import { HRRECORD_HSC1_API } from "constants/ApiConstant";
const HRRecordService = {};

HRRecordService.searchGrid = function (data) {
  return axiosInstance.post(`${HRRECORD_HSC1_API}/search`, data);
};

HRRecordService.addHSC1 = function (data) {
  return axiosInstance.post(`${HRRECORD_HSC1_API}`, data);
};

HRRecordService.updateHSC1 = function (data) {
  return axiosInstance.put(`${HRRECORD_HSC1_API}`, data);
};

HRRecordService.deleteHSC1 = function (id) {
  return axiosInstance.delete(`${HRRECORD_HSC1_API}?id=${id}`);
};

export default HRRecordService;
