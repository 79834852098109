import React from "react";
import { Routes as RouterRoutes, Route, Navigate, useLocation } from "react-router-dom";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { protectedRoutes, publicRoutes, publicShareRoute } from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute, {PUBLICROUTES} from "./PublicRoute";
import AppRoute from "./AppRoute";
import PublicShareRoute  from './PublicShareRoute';


const Routes = () => {
  const {pathname} = useLocation();
  const isPublicRoute = PUBLICROUTES.find(item => pathname.includes(item));
  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
        />
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicShareRoute />}>
        {publicShareRoute.map((route, index) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                  blankLayout={isPublicRoute}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;