import axiosInstance from "auth/FetchInterceptor";

const { SEARCH_COMMENT_API } = require("constants/ApiConstant");
const { COMMENT_API } = require("constants/ApiConstant");

const CommentService = {};

CommentService.searchGrid = function (data) {
  return axiosInstance.post(SEARCH_COMMENT_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CommentService.getCommentById = function (id) {
  return axiosInstance.get(`${COMMENT_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CommentService.create = function (data) {
  return axiosInstance.post(COMMENT_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CommentService.update = function (data) {
  return axiosInstance.put(`${COMMENT_API}/update`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CommentService.delete = function (id) {
  return axiosInstance.delete(`${COMMENT_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default CommentService;
