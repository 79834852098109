import axiosInstance from "auth/FetchInterceptor";
import {
  EMPLOYEE_GRID_API,
  DETAIL_API,
  EMPLOYEE_API,
  CHANGE_STATE_EMPLOYEE_API,
  EXPORT_EXCEL_EMPLOYEE_API,
  IMPORT_EXCEL_EMPLOYEE_API,
  IMPORT_DSNV_EMPLOYEE_API,
  IMPORT_HISTORY_API,
  IMPORT_EMPLOYEE_APPLIER_API,
} from "constants/ApiConstant";
const EmployeeService = {};

EmployeeService.searchGrid = function (data) {
  return axiosInstance.post(EMPLOYEE_GRID_API, data);
};

EmployeeService.detail = function (id) {
  return axiosInstance.get(`${DETAIL_API}/${id}`, null);
};

EmployeeService.importDsnv = function (id) {
  return axiosInstance.get(`${IMPORT_DSNV_EMPLOYEE_API}?transId=${id}`);
};

EmployeeService.importHistory = function (id) {
  return axiosInstance.get(`${IMPORT_HISTORY_API}?branchId=${id}`);
};

EmployeeService.create = function (data) {
  return axiosInstance.post(EMPLOYEE_API, data);
};

EmployeeService.exportExcel = function (data) {
  return axiosInstance.post(EXPORT_EXCEL_EMPLOYEE_API, data);
};

EmployeeService.importExcel = function (data) {
  return axiosInstance.post(IMPORT_EXCEL_EMPLOYEE_API, data);
};

EmployeeService.importDsnvApplier = function (data) {
  return axiosInstance.post(IMPORT_EMPLOYEE_APPLIER_API, data);
};

EmployeeService.update = function (data) {
  return axiosInstance.put(EMPLOYEE_API, data);
};

EmployeeService.changeState = function (data) {
  return axiosInstance.put(CHANGE_STATE_EMPLOYEE_API, data);
};

EmployeeService.delete = function (id) {
  return axiosInstance.delete(`${EMPLOYEE_API}/${id}`, null);
};

export default EmployeeService;
