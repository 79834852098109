import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import rating from "./slices/ratingSlice";
import report from "./slices/reportSlice";
import department from "./slices/departmentSlice";
import category from "./slices/categorySlice";
import employee from "./slices/employeeSlice";
import userPermit from "./slices/userPermissionSlice";
import role from "./slices/rolePermitSlice";
import setting from "./slices/settingSlice";
import posts from "./slices/postsSlice";
import hospitalManagement from "./slices/hospitalManagementSlice";
import doctorManagement from "./slices/doctorManagementSlice";
import appointment from "./slices/appointmentSlice";
import notification from "./slices/notificationSlice";
import ambulance from "./slices/ambulanceSlice";
import comment from "./slices/commentSlice";
import bhytCategory from "./slices/bhxhCategorySlice";
import hrRecord from "./slices/hrRecordSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    rating,
    report,
    department,
    category,
    employee,
    userPermit,
    role,
    posts,
    setting,
    hospitalManagement,
    doctorManagement,
    appointment,
    notification,
    ambulance,
    comment,
    bhytCategory,
    hrRecord,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
