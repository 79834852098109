import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ReportService from "services/ReportService.js";

export const getFeedbackFromCustomer = createAsyncThunk(
  "report/getFeedbackFromCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getFeedBackFromCustomer(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getReportThisHospital = createAsyncThunk(
  "report/getReportThisHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportThisHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getReportAllHospital = createAsyncThunk(
  "report/getReportAllHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportAllHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getReportKcbAllHospital = createAsyncThunk(
  "report/getReportKcbAllHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportKcbAllHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getReportKcbHospital = createAsyncThunk(
  "report/getReportKcbHospital",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getReportKcbHospital(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  customerList: [],
  reportHospital: {},
  reportAllHospital: {},
  reportKcbAllHospital: {},
  reportKcbHospital: {},
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbackFromCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedbackFromCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
      })
      .addCase(getFeedbackFromCustomer.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportAllHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportAllHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportAllHospital = action.payload;
      })
      .addCase(getReportAllHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportThisHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportThisHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportHospital = action.payload;
      })
      .addCase(getReportThisHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportKcbAllHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportKcbAllHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportKcbAllHospital = action.payload;
      })
      .addCase(getReportKcbAllHospital.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getReportKcbHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportKcbHospital.fulfilled, (state, action) => {
        state.loading = false;
        state.reportKcbHospital = action.payload;
      })
      .addCase(getReportKcbHospital.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = reportSlice.actions;

export default reportSlice.reducer;
