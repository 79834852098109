export const API_BASE_URL = "https://your-api-url.com/";

const PREFIX = "/en/api/v1.0";
export const UPLOAD_FILE = `${PREFIX}/Media/upload`;
export const DELETE_FILE = "/api/fileid";

export const GET_PROFILE = `${PREFIX}/User/profile`;
export const ACCOUNT_LOGIN = `${PREFIX}/User/login`;
export const SIGN_OUT = `${PREFIX}/User/logout`;
export const FORGOT_PASSWORD = `${PREFIX}/User/password/forgot-by-email`;
export const RESET_PASSWORD = `${PREFIX}/User/password/reset-by-email`;
export const CHANGE_PASSWORD = `${PREFIX}/User/password/change`;
export const SEARCH_BY_ROLES = `${PREFIX}/User/search-by-roles`;
export const REFRESH_TOKEN_API = `${PREFIX}/TokenManager/refresh-token`;

const RATING_API = `${PREFIX}/Rating`;
export const RATING_GETBYCODE_API = `${RATING_API}/get-by-code`;
export const RATING_SUBMIT_API = `${RATING_API}/submit`;
export const ESIGN_CHECKTHE_API = `${PREFIX}/Esign/check-the`;
export const ESIGN_SUBMIT_API = `${PREFIX}/Esign/submit`;

const REPORT_API = `${PREFIX}/Report`;
export const REPORT_FEEDBACK_FROM_CUSTOMER_API = `${REPORT_API}/feedback-from-customer`;
export const REPORT_CHART_HOSPITAL_API = `${REPORT_API}/chart-hospital`;
export const REPORT_CHART_ALL_HOSPITAL_API = `${REPORT_API}/chart-all-hospital`;
export const REPORT_CHART_KCB_ALL_HOSPITAL_API = `${REPORT_API}/chart-kcb-all-hospital`;
export const REPORT_CHART_KCB_HOSPITAL_API = `${REPORT_API}/chart-kcb-hospital`;

const CDHA_API = `${PREFIX}/KetQuaCdha`;
export const CDHA_VIEW_API = `${CDHA_API}/cdha`;

export const DEPARTMENT_API = `${PREFIX}/Department`;
export const DEPARTMENT_SEARCH_GRID_API = `${DEPARTMENT_API}/search-grid`;
export const DEPARTMENT_HOSPITALS_API = `${DEPARTMENT_API}/hospitals`;
const CATEGORY_API = `${PREFIX}/Category`;
export const GET_BY_JOB_POSITION_API = `${CATEGORY_API}/get-by-job-position`;
export const GET_BY_JOB_SPECCIALIZE_API = `${CATEGORY_API}/get-by-job-Specialize`;
export const GET_BY_JOB_TITLE_API = `${CATEGORY_API}/get-by-job-title`;
export const GET_BY_DEPARTMENT_API = `${CATEGORY_API}/get-by-department`;
export const GET_BY_WORK_STATUS_API = `${CATEGORY_API}/get-by-work-status`;
export const GET_BY_HIS_DEPARTMENT_API = `${CATEGORY_API}/get-by-his-department`;
export const GET_BY_DEPARTMENT_LEVEL_API = `${CATEGORY_API}/get-by-department-level`;
export const GET_BY_AUTH_PERMIT = `${CATEGORY_API}/get-by-auth-permit`;
export const GET_DEAPARTMENT_BY_BRANCH = `${CATEGORY_API}/department-by-branch`;
export const GET_SPECALIZE_BY_BRANCH = `${CATEGORY_API}/speciality`;
export const GET_TIME_SLOT = `${CATEGORY_API}/timeslot`;
export const GET_ALL_HOSPITAL_API = `${CATEGORY_API}/hospital`;

export const EMPLOYEE_API = `${PREFIX}/Employee`;
export const EMPLOYEE_GRID_API = `${EMPLOYEE_API}/search-grid`;
export const DETAIL_API = `${EMPLOYEE_API}/detail`;
export const CHANGE_STATE_EMPLOYEE_API = `${EMPLOYEE_API}/change-state`;
export const EXPORT_EXCEL_EMPLOYEE_API = `${EMPLOYEE_API}/export-excel`;
export const IMPORT_EXCEL_EMPLOYEE_API = `${EMPLOYEE_API}/import-excel`;
export const IMPORT_DSNV_EMPLOYEE_API = `${EMPLOYEE_API}/import-dsnv`;
export const IMPORT_HISTORY_API = `${EMPLOYEE_API}/import-history`;
export const IMPORT_EMPLOYEE_APPLIER_API = `${EMPLOYEE_API}/import-dsnv-applier`;

const USER_PERMISSION_API = `${PREFIX}/UserPermission`;
export const USER_BRANCH_API = `${USER_PERMISSION_API}/user-branchs`;
export const USER_SCOPE_API = `${USER_PERMISSION_API}/user-scopes`;
export const USER_ROLE_API = `${USER_PERMISSION_API}/user-roles`;

const ROLE_PERMIT_API = `${PREFIX}/RolePermission`;
export const ROLE_BY_SCOPE_API = `${ROLE_PERMIT_API}/role-by-scope`;
export const ROLE_DETAIL_API = `${ROLE_PERMIT_API}/role-detail`;
export const ADD_ROLE_API = `${ROLE_PERMIT_API}/add-role`;
export const UPDATE_ROLE_API = `${ROLE_PERMIT_API}/update-role`;
export const DELETE_ROLE_API = `${ROLE_PERMIT_API}/delete-role`;
export const UPDATE_ROLE_PERMIT_API = `${ROLE_PERMIT_API}/update-role-permit`;
export const ROLE_BY_PERMIT = `${ROLE_PERMIT_API}/role-by-permit`;

const SETTING_API = `${PREFIX}/Setting`;
export const SEARCH_BANNER_API = `${SETTING_API}/banner/search-grid`;
export const BANNER_API = `${SETTING_API}/banner`;
export const SETTING = `${SETTING_API}`;

export const POSTS_API = `${PREFIX}/Blog`;
export const SEARCH_POSTS_API = `${POSTS_API}/search-grid`;

export const HOSPITAL_MANAGER_API = `${PREFIX}/HospitalManagement`;
export const DOCTOR_MANAGEMENT_API = `${PREFIX}/DoctorManagement`;
export const APPOINTMENT_API = `${PREFIX}/Appointment`;
export const NOTIFICATION_API = `${PREFIX}/Notification`;
export const AMBULANCE_API = `${PREFIX}/Ambulance`;

export const COMMENT_API = `${PREFIX}/Comment`;
export const SEARCH_COMMENT_API = `${COMMENT_API}/search-grid`;

// BHXH Category
const BHYT_CATEGORY_API = `${PREFIX}/BhytCategory`;
export const BHYT_SEARCH_DSNV = `${BHYT_CATEGORY_API}/search-dsnv`;
export const BHYT_IMPORT_DSNV = `${BHYT_CATEGORY_API}/import-dsnv`;
export const BHYT_APPLY_DSNV = `${BHYT_CATEGORY_API}/apply-dsnv`;

const HRRECORD_API = `${PREFIX}/HRRecord`;
export const HRRECORD_HSC1_API = `${HRRECORD_API}/hsc1`
